import React from 'react';
import {Card,  CardHeader} from "../../_metronic/_partials/controls";



function HomePage(props) {

    return (
        <div>
            <Card>
                <CardHeader>
                    <div className="card-title">
                        <h3 className="card-label">
                            <strong>欢迎使用腾飞讲堂后管理系统</strong>
                        </h3>
                    </div>
                </CardHeader>
            </Card>
        </div>
    );
}

export default HomePage;