import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "../util/UIHelpers";

const UIContext = createContext();

export function useUIContext() {
  return useContext(UIContext);
}

export const UIConsumer = UIContext.Consumer;

export function UIProvider({UIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [loading, setLoading] = useState(false);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    loading,
    setLoading,
    queryParams,
    setQueryParamsBase,
    setQueryParams,
  };

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
}
