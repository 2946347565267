import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import * as auth from "../_redux/authRedux";
import {login} from "../../../api/UserApi";

function Login(props) {

    const [loading, setLoading] = useState(false);
    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .min(3, "不可小于3个字符")
            .max(50, "不可大于50字符")
            .required("请输入账号"),
        password: Yup.string()
            .min(3, "不可小于3个字符")
            .max(50, "不可大于50字符")
            .required("请输入密码"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }
        return "";
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: LoginSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            enableLoading();
            props.logout();
            setTimeout(() => {
                login(values.email, values.password)
                    .then(response => {
                        disableLoading();
                        setSubmitting(false);
                        if (response.code === 200) {
                            let user = {
                                username: response.data.username,
                                truename: response.data.truename,
                                functions: response.data.functions
                            };
                            props.login(response.data.token, user);
                        } else {
                            setStatus(response.msg);
                        }
                    })
                    .catch((error) => {
                        disableLoading();
                        setSubmitting(false);
                        setStatus(
                            "服务器异常"
                        );
                    });
            }, 1000);
        },
    });

    return (
        <>
            <div className="login-signin">
                <div className="mb-20">
                    <h3 className="opacity-40 font-weight-normal">
                        <img alt="logo" src="/media/logos/logo-login-copy.png" width="180"/>
                    </h3>
                    <div className="mt-20 mb-20">
                        <h3 className="text-white opacity-40 font-weight-normal">请输入您的账号和密码进行登录</h3>
                    </div>
                </div>
                <form className="form" id="kt_login_signin_form" onSubmit={formik.handleSubmit}>
                    {formik.status ? (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible rounded-pill">
                            <div className="alert-text font-weight-bold">{formik.status}</div>
                        </div>
                    ) : (<></>)}
                    <div className="form-group">
                        <input
                            placeholder="请输入您的账号"
                            type="text"
                            className={`form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8 ${getInputClasses(
                                "email"
                            )}`}
                            name="email"
                            {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.email}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <input
                            placeholder="请输入您的密码"
                            type="password"
                            className={`form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8 ${getInputClasses(
                                "password"
                            )}`}
                            name="password"
                            {...formik.getFieldProps("password")}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.password}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group text-center mt-  10">
                        <button
                            id="kt_login_signin_submit"
                            type="submit"
                            disabled={formik.isSubmitting}
                            className={`btn btn-pill btn-primary opacity-90 px-15 py-3`}
                        >
                            <span>登录</span>
                            {loading && <span className="ml-3 spinner spinner-white"></span>}
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(Login));
