import axios from "axios";

export const LOGIN_URL = "/api/management/v1/admin/login";
export const SHOP_INFO_URL = "/api/management/v1/shop_bridge/show";
export const ADD_SHOP_URL = "/api/management/v1/shop_bridge/create";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

export function login(username, password) {
  return axios.post(LOGIN_URL, { username, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function getShopInfo(id) {
  return axios.get(SHOP_INFO_URL, {
    params: {
      id: id
    } });
}
export function addShop(shop_category,name,sn,shop_id,server_url,callback_url,products) {
  return axios.post(ADD_SHOP_URL, {shop_category,name,sn,shop_id,server_url,callback_url,products});
}

export function updateShop(id,shop_category,name,sn,shop_id,server_url,callback_url,products) {
  return axios.post(ADD_SHOP_URL, {id,shop_category,name,sn,shop_id,server_url,callback_url,products});
}
