/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router-dom";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import CheckAuthFunction from "../../../../../app/modules/Manage/_partials/CheckAuthFunction";

export function AsideMenuList({layoutProps}) {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };

    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                <li className={`menu-item ${getMenuItemActive("/dashboard", false)}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/dashboard">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
                        </span>
                        <span className="menu-text">首页</span>
                    </NavLink>
                </li>
                {/*会员管理*/}
                {CheckAuthFunction("users")&&<li className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/pages/user", true)}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover">
                    <NavLink className="menu-link menu-toggle" to="/pages/user">
                        <span className="svg-icon menu-icon">
                             <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}/>
                        </span>
                        <span className="menu-text">会员管理</span>
                        <i className="menu-arrow"/>
                    </NavLink>
                    <div className="menu-submenu">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                  <span className="menu-text">会员管理</span>
                                </span>
                            </li>
                            <li className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/pages/user/list", false)}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover">
                                <NavLink className="menu-link menu-toggle" to="/pages/user/list">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">会员列表</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>}
                {/*广告位管理*/}
                {CheckAuthFunction("ads")&&<li className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/pages/advertise", true)}`}
                     aria-haspopup="true"
                     data-menu-toggle="hover">
                    <NavLink className="menu-link menu-toggle" to="/pages/advertise">
                        <span className="svg-icon menu-icon">
                             <SVG
                                 src={toAbsoluteUrl("/media/svg/icons/Devices/Display3.svg")}/>
                        </span>
                        <span className="menu-text">广告位管理
                        </span>
                        <i className="menu-arrow"/>
                    </NavLink>
                    <div className="menu-submenu">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                  <span className="menu-text">广告位管理</span>
                                </span>
                            </li>
                            <li className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/pages/advertise/list", false)}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover">
                                <NavLink className="menu-link menu-toggle" to="/pages/advertise/list">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">广告位列表</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>}
                {/*试卷管理*/}
                {CheckAuthFunction("papers")&&<li className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/pages/paper", true)}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover">
                    <NavLink className="menu-link menu-toggle" to="/pages/paper">
                        <span className="svg-icon menu-icon">
                             <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")}/>
                        </span>
                        <span className="menu-text">试卷管理</span>
                        <i className="menu-arrow"/>
                    </NavLink>
                    <div className="menu-submenu ">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                  <span className="menu-text">试卷管理</span>
                                </span>
                            </li>
                            <li className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/pages/paper/paper-list", false)}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover">
                                <NavLink className="menu-link menu-toggle" to="/pages/paper/paper-list">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">试卷列表</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>}
                {/*技能管理*/}
                {CheckAuthFunction("skills")&&<li className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/pages/skill", true)}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover">
                    <NavLink className="menu-link menu-toggle" to="/pages/skill">
                        <span className="svg-icon menu-icon">
                             <SVG
                                 src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-top-panel-4.svg")}/>
                        </span>
                        <span className="menu-text">技能管理
                        </span>
                        <i className="menu-arrow"/>
                    </NavLink>
                    <div className="menu-submenu">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                  <span className="menu-text">技能管理</span>
                                </span>
                            </li>
                            <li className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/pages/skill/skill-list", false)}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover">
                                <NavLink className="menu-link menu-toggle" to="/pages/skill/skill-list">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">技能列表</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>}
                {/*报名管理*/}
                {CheckAuthFunction("applys")&&<li className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/pages/apply", true)}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover">
                    <NavLink className="menu-link menu-toggle" to="/pages/apply">
                        <span className="svg-icon menu-icon">
                             <SVG
                                 src={toAbsoluteUrl("/media/svg/icons/Home/Box.svg")}/>
                        </span>
                        <span className="menu-text">报名管理
                        </span>
                        <i className="menu-arrow"/>
                    </NavLink>
                    <div className="menu-submenu">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                  <span className="menu-text">报名管理</span>
                                </span>
                            </li>
                            <li className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/pages/apply/list", false)}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover">
                                <NavLink className="menu-link menu-toggle" to="/pages/apply/list2">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">报名管理列表</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>}
                {CheckAuthFunction("apply_logs")&&<li className={`menu-item ${getMenuItemActive("/pages/apply/log", false)}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/pages/apply/log">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
                        </span>
                        <span className="menu-text">开课日志</span>
                    </NavLink>
                </li>}
                {/*视频管理*/}
                {CheckAuthFunction("videos")&&<li className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/pages/video", true)}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover">
                    <NavLink className="menu-link menu-toggle" to="/pages/video">
                        <span className="svg-icon menu-icon">
                             <SVG
                                 src={toAbsoluteUrl("/media/svg/icons/Devices/Video-camera.svg")}/>
                        </span>
                        <span className="menu-text">视频管理
                        </span>
                        <i className="menu-arrow"/>
                    </NavLink>
                    <div className="menu-submenu">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                  <span className="menu-text">视频管理</span>
                                </span>
                            </li>
                            <li className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/pages/video/video-set-list", false)}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover">
                                <NavLink className="menu-link menu-toggle" to="/pages/video/video-set-list">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">视频集列表</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>}
                {/*招工信息*/}
                {CheckAuthFunction("activities")&&<li className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/pages/activities", true)}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover">
                    <NavLink className="menu-link menu-toggle" to="/pages/activities">
                        <span className="svg-icon menu-icon">
                             <SVG
                                 src={toAbsoluteUrl("/media/svg/icons/Food/Coffee1.svg")}/>
                        </span>
                        <span className="menu-text">招工信息
                        </span>
                        <i className="menu-arrow"/>
                    </NavLink>
                    <div className="menu-submenu">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                  <span className="menu-text">招工信息</span>
                                </span>
                            </li>
                            <li className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/pages/activities/activities-list", false)}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover">
                                <NavLink className="menu-link menu-toggle" to="/pages/activities/activities-list">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">招工信息列表</span>
                                </NavLink>
                            </li>
                            <li className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/pages/activities/register-list", false)}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover">
                                <NavLink className="menu-link menu-toggle" to="/pages/activities/register-list">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">应聘列表</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>}
                {/*链接信息*/}
                {CheckAuthFunction("platforms")&&<li className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/pages/platform", true)}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover">
                    <NavLink className="menu-link menu-toggle" to="/pages/platform">
                        <span className="svg-icon menu-icon">
                             <SVG
                                 src={toAbsoluteUrl("/media/svg/icons/Navigation/Route.svg")}/>
                        </span>
                        <span className="menu-text">链接信息
                        </span>
                        <i className="menu-arrow"/>
                    </NavLink>
                    <div className="menu-submenu">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                  <span className="menu-text">链接信息</span>
                                </span>
                            </li>
                            <li className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/pages/platform/platform-list", false)}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover">
                                <NavLink className="menu-link menu-toggle" to="/pages/platform/platform-list">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">链接列表</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>}
                {/*系统配置*/}
                {CheckAuthFunction("settings")&&<li className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/pages/setting", true)}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover">
                    <NavLink className="menu-link menu-toggle" to="/pages/setting">
                        <span className="svg-icon menu-icon">
                             <SVG
                                 src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}/>
                        </span>
                        <span className="menu-text">系统配置</span>
                        <i className="menu-arrow"/>
                    </NavLink>
                    <div className="menu-submenu">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                  <span className="menu-text">系统配置</span>
                                </span>
                            </li>
                            <li className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/pages/setting/Setting", false)}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover">
                                <NavLink className="menu-link menu-toggle" to="/pages/setting/Setting">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">系统设置</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>}
            </ul>

        </>
    );
}
