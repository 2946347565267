/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Link, Switch, Redirect} from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {ContentRoute} from "../../../../_metronic/layout"
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

export function AuthPage() {
  return (
      <>
        <div className="d-flex flex-column flex-root">
          {/*begin::Login*/}
          <div className="login login-5 login-signin-on d-flex flex-row-fluid" id="kt_login">
            <div className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
                 style={{backgroundImage: 'url(/media/bg/bg-2.jpg)'}}>
              {/*login整体框体 col-3 */}
              <div className="login-form col-3 text-center text-white p-7 position-relative overflow-hidden">
                {/*begin::Login Header*/}
                <div className="d-flex flex-center mb-15">
                  <a href="#">
                    <img src="assets/media/logos/logo-letter-13.png" className="max-h-75px" alt=""/>
                  </a>
                </div>
                {/*end::Login Header*/}
                <Switch>
                  <ContentRoute path="/auth/login" component={Login}/>
                  <ContentRoute path="/auth/registration" component={Registration}/>
                  <ContentRoute
                    path="/auth/forgot-password"
                    component={ForgotPassword}
                  />
                  <Redirect from="/auth" exact={true} to="/auth/login"/>
                  <Redirect to="/auth/login"/>
                </Switch>
              </div>
            </div>
          </div>
          {/*end::Login*/}
        </div>
      </>
  );
}
