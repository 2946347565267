export const tatusCssClasses = ["primary", "success", "info", "warning","danger"];
export const tatusTitles = ["Suspended", "Active", "Pending", ""];
export const CustomerTypeCssClasses = ["success", "primary", ""];
export const CustomerTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 }
];
export const initialFilter = {
  sortOrder: "asc", // asc||desc
  sortField: "id",
  page: 1,
  limit: 10
};
