import axios from "axios";

//登录
export const LOGIN = "/api/management/v1/admins/login";
export const USER_LEVELS = "/api/management/v1/users/user_levels";
export const USER_LIST = "/api/management/v1/users/list";
export const USER_DETAIL = "/api/management/v1/users/detail";
export const USER_STATISTICS = "/api/management/v1/users/detail_statistics";
export const USER_POINTS = "/api/management/v1/users/points";
export const USER_INVITES = "/api/management/v1/users/invites";
export const USER_LEVELS_UPDATE = "/api/management/v1/users/user_levels_update";
export const POINT_ACTION_OPTIONS = "/api/management/v1/users/point_action_options";
export const CHILDREN_TREE = "/api/management/v1/users/children_tree";
export const ROOT_USER = "/api/management/v1/users/root_user";


export function login(username, password) {
    return axios.post(LOGIN, {username, password});
}

export function getUserLv() {
    return axios.get(USER_LEVELS);
}

export function getPointActionOptions() {
    return axios.get(POINT_ACTION_OPTIONS);
}

export function getUserList(queryParams) {
    return axios.get(USER_LIST, {
        params: queryParams
    });
}

export function getUserDetail(id) {
    return axios.get(USER_DETAIL, {
        params: {
            id
        }
    });
}

export function getUserStatistics(id) {
    return axios.get(USER_STATISTICS, {
        params: {
            id
        }
    });
}

export function getUserPoints(queryParams) {
    return axios.get(USER_POINTS, {
        params: queryParams

    });
}

export function getUserInvites(queryParams) {
    return axios.get(USER_INVITES, {
        params: queryParams
    });
}

export function userLevelsUpdate(levels) {
    return axios.patch(USER_LEVELS_UPDATE, levels)
}

export function getUserTree(id) {
    return axios.get(CHILDREN_TREE, {
        params: {
            id
        }
    });
}
export function getRootUserId(id) {
    return axios.get(ROOT_USER, {
        params: {
            id
        }
    });
}




